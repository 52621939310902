.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 41px !important;
  background-color: #fcfdf2 !important;
}
.react-tel-input .flag-dropdown {
  /* position: absolute;
  top: 0;
  bottom: 0;
  padding: 0; */
  /* background-color: #f5f5f5; */
  /* border: 1px solid #818181; */
  border-radius: 7px 0 0 7px !important;
}

.bg-custom {
  background-image: url(Assets/images/NewBG.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.bg-custom-Job {
  background-image: url(Assets/DreamJobLogos/bgImage.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-custom-skills {
  background-image: url(Assets/EnhanceSkillsLogos/bgImageBlue.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}



.active {
  color: red;
  font-weight: bold;
} 

.bg-custom1 {
  background-image: url(Assets/testtimonials/image1.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-custom2 {
  background-image: url(Assets/testtimonials/image2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-custom3 {
  background-image: url(Assets/testtimonials/image3.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-custom4 {
  background-image: url(Assets/images//DottedFrame.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-custom5 {
  background-image: url(Assets/images/hoverBg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-custom-blue {
  background-color: blueviolet;
}

