@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-slide>div{
  margin: 0 10px;
}
.slick-arrow{
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.slick-next:before, .slick-prev:before {
    content: '→';
    color: #192C56 !important;
}
/* Hide scrollbar for all elements */
.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.ant-pagination-options{
  display: block !important;
}
body {
  margin: 0;
  font-family: 'Roboto',"Inter", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-dots {
  position: absolute;
  bottom: -15px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0px;
  margin-bottom: 5px;
  list-style: none;
  text-align: center;
}