/* Slider.css */
 .slick-prev, .slick-next {
    font-size: 24px;
    color: #000 !important;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;                                      
    background: rgb(255, 255, 255)!important;
    z-index: 1;
  } 
  
  .slick-prev {
    left: -35px !important;
  }
  
  .slick-next {
    right: -40px !important;
  }   
  
 .slick-prev:before, .slick-next:before {
    font-size: 24px !important;
    color: #000 !important;
    z-index: 1;
    display: none;
  }  
   

   /* .custom-prev-arrow {
    @apply absolute top-1/2 left-4 transform -translate-y-1/2 text-black p-2 rounded-full;
    z-index: 10 !important;
    cursor: pointer;
    color:black !important;
    width:40px;
    height:40px;
  }
  
  .custom-next-arrow {
    @apply absolute top-1/2 right-4 transform -translate-y-1/2 text-black bg-gray-800 p-2 rounded-full;
    z-index: 10;
    cursor: pointer;
    color:black !important;
    width:40px;
    height:40px;
    
  } */